<script>


import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import {getTaxApi} from '@/api/accounting/tax'
import { financial } from '@/api/misc'
import moment from 'moment'
/**
 * User list component
 */
export default {
  page: {
    title: "Agent T4A",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      contactListData: [],
      title: "Agent T4A",
      items: [
        {
          text: "Accounting",
          href: "/",
        },
        {
          text: "Agent T4A",
          active: true,
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 50,
      filter: null,
      filterOn: [],
      sortBy: "value",
      sortDesc: false,
      fields: [
       
        {
          key: "name",
          sortable: true,
        },
        {
          key: "Vin",
          sortable: true,
        },
        {
          key: "Gross",
          sortable: true,
        },
        {
          key: "Gst",
          sortable: true,
        },
        {
          key: "Office",
          sortable: true,
        },
        {
          key: "Action",
          sortable: true,
        },
      ],

      searchKey: "",
      submitted: false,
    };
  },
  components: {
    Layout,
    PageHeader,
  },
  
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.totalRows
    },
  },

  created() {
   
  },
  mounted() {
    this.queryList()
  },
  methods: {

    f(v, d = 2) {
      return financial(v,d)
    },


    queryList() {
      let data = {
        page : {page : this.currentPage, page_size: this.perPage}
      }
      
      getTaxApi().agent_t4a_list(data).then((res)=>{
        this.contactListData = []
          res.data.map((o) =>{
          this.contactListData.push(o)
        })  
        this.totalRows = res.page.total;

      })
     
    },

    pageChange(p) {
     
      this.currentPage = p
      this.queryList()
    },


    preview(agent) {
      console.log(agent)
    },

    t4a_detail(agent) {
      let start_date = moment()
      let end_date   = moment()
      start_date.set({'month' : 0, 'date': 1})
      this.$router.push({name : 'accounting-tax-agent-gst', query : {agent_id : agent.id, agent_name : agent.full_name, start_date : start_date.format("YYYY-MM-DD"), end_date : end_date.format("YYYY-MM-DD")}})
    },

    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

  },

};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <div class="mb-3">
                  <h5 class="card-title">
                    Agent List
                    <span class="text-muted fw-normal ms-2">({{ this.totalRows }})</span>
                  </h5>
                </div>
              </div>
              <!-- end col -->

              <div class="col-md-6">
                <div
                  class="d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2 mb-3"
                >
                <div class="search-box ">
                    <div class="position-relative">
                      <input
                        type="text"
                        class="form-control bg-light border-light rounded"
                        v-model="searchKey"
                        placeholder="Search..."
                        @keyup.enter="onEnterSearch"
                      />
                      <i class="uil uil-search search-icon"></i>
                    </div>
                  </div>
                 
                </div>
              </div>
              <!-- end col -->
            </div>
            <!-- end row -->

            <div class="table-responsive">
              <div class="table align-middle table-nowrap">
                <b-table
                  :items="contactListData"
                  :fields="fields"
                  responsive="sm"
                  :per-page="perPage"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  @filtered="onFiltered"
                  class="table-check"
                >

                  <template #cell(name)="data">
                   
                    <router-link
                      :to="{name : 'brokerage-agent-view', params:{agent : data.item, agent_id : data.item.id+''}}"
                      class="text-body fw-medium"
                      >{{ data.item.full_name }}</router-link
                    >
                  </template>
                  <template #cell(Vin)="data">
                    {{ data.item.code }}
                  </template>
                
                  <template #cell(Gross)="data">
                    ${{ f(data.item.gross_income).toLocaleString() }}
                  </template>

                  <template #cell(Gst)="data">
                    ${{ f(data.item.gst).toLocaleString() }}
                  </template>

                  <template #cell(office)="data">
                    {{ data.item.office_code }}
                  </template>

                  <template #cell(action)="data">
                    <b-dropdown variant="light" right size="sm">
                      <template v-slot:button-content>
                        <i class="uil uil-ellipsis-h"></i>
                      </template>
                      <b-dropdown-item @click="preview(data.item)">T4A Slip</b-dropdown-item>
                      <b-dropdown-item @click="t4a_detail(data.item)">T4A Break Down</b-dropdown-item>
                    </b-dropdown>
                  </template>
                </b-table>
              </div>
            </div>
            <!-- end table responsive -->

            <div class="row g-0 text-center text-sm-start">
              <!-- end col -->
              <div class="col-sm-12">
                <div class="row">
                  <div class="col">
                    <div
                      class="dataTables_paginate paging_simple_numbers float-end"
                    >
                      <!-- pagination -->
                      <b-pagination
                        class="pagination-rounded"
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        @change="pageChange"
                      ></b-pagination>
                    </div>
                  </div>
                </div>
              </div>
              <!-- end col -->
            </div>
            <!-- end row -->


          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
